import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { typography, mq } from "styles"
import { Waitlist } from "components/Products/Waitlist/Waitlist"
import { Incrementor } from "components/UI/Incrementor/Incrementor"
import { ProductContext } from "contexts/ProductContext"
import { AddToCartButton } from "components/Products/AddToCartButton"
import { Callouts } from "components/Products/Callouts"
import RulerSvg from "assets/svg/ruler.svg"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { tracking } from "utils/tracking"
import { QuickAddProductItem } from "components/ProductItem/QuickAddProductItem"
import { AccordionItem } from "components/AccordionItem/AccordionItem"
import { useProductList } from "hooks/useProductList"
import { usdFmt } from "utils/priceUtils"
import { ShopifyProduct, ShopifyProductVariant } from "typings/graphql"
import { VariantConn } from "components/FBT/FBT.types"
import { WaitlistSignup } from "./Waitlist/WaitlistSignup"
import { decodeVariantId } from "utils/decodeId"
import { AffirmTeaserSanity } from "components/UI/Affirm/AffirmTeaser.sanity"
import { ProductPageVariantOptions } from "./VariantOptions"
import { useIntersection } from "react-use"
import { useHeaderHeight } from "hooks/useHeaderHeight"

const FreeShippingCallout = styled.span`
  color: ${({ theme }) => theme.dark?.text?.secondary?.opacityHex};
  ${typography.bodySmall};
  display: block;
  min-height: 1px;
  text-align: center;
`

const CalloutContainer = styled.div`
  & > span {
    margin: 24px 0;
  }
`

const ActionContainer = styled.div<{ $hasPrice: boolean }>`
  display: grid;
  ${({ $hasPrice }) =>
    $hasPrice &&
    `
    grid-template-columns: 1fr 5fr;
    grid-gap: 24px;
  `}
`

const DimensionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 20px;
    color: ${({ theme }) => theme.localTheme?.ui?.icon?.opacityHex};
  }
`

const DimensionsButton = styled(NoStyleButton)`
  ${typography.defaultLink}
  ${typography.bodySmall};

  margin-left: 7px;
`

const DimensionsShippingContainer = styled.div<{
  $dimensionsButtonEnabled: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $dimensionsButtonEnabled }) =>
    $dimensionsButtonEnabled ? "space-between" : "center"};
  margin-top: 24px;
  margin-bottom: 24px;
`

const Container = styled.div`
  margin-top: 16px;
  ${mq.minWidth("lg")} {
    margin-bottom: 32px;
  }
`
const AddOnAccordion = styled(AccordionItem)`
  border-bottom: 1px solid ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};
  margin-bottom: 16px;
`
const AddOnProductContainer = styled(QuickAddProductItem)`
  padding: 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const AddedVariantsSummary = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme?.dark?.text?.primary?.opacityHex};
  padding-bottom: 16px;
  margin-bottom: 25px;
  border-bottom: 1px solid ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};
`
const AddedProductsTitle = styled.div`
  color: ${({ theme }) => theme?.dark?.text?.secondary?.opacityHex};
`

const AddOnLineItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const AddOnLineItem = styled.div`
  display: flex;
`
const AddOnLineItemTitle = styled.div`
  flex-grow: 1;
`

const TotalLine = styled(AddOnLineItem)`
  margin-top: 20px;
`
const AddOnsTotal = styled(AddOnLineItemTitle)`
  font-weight: bold;
`

export type AddToCartProps = {
  loading: boolean
  dimensionsButtonEnabled: boolean
  onAddToCartVisibilityChange?: (visible: boolean) => void
}

export const AddToCart = ({
  loading,
  dimensionsButtonEnabled,
  onAddToCartVisibilityChange,
}: AddToCartProps) => {
  const headerHeight = useHeaderHeight()
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: `-${headerHeight}px 0px 0px 0px`,
    threshold: 0,
  })

  useEffect(() => {
    if (!onAddToCartVisibilityChange || !intersection) {
      return
    }

    onAddToCartVisibilityChange(intersection.isIntersecting)
  }, [onAddToCartVisibilityChange, intersection])

  const data = useContext(ProductContext)
  const {
    item,
    selectedVariant,
    quantity,
    waitlistPopupVisible,
    setWaitlistPopupVisible,
    setQuantity,
    addedProductVariantQuantities,
    addedProductVariantsPrice,
    updateAddOnProductVariantQuantity,
    viewDimensionsClicked,
    actionRequired,
    actionRequiredText,
    updateSelectedVariant,
    selectableOptions,
  } = data

  const { plItems: addOnProducts } = useProductList(item?.addOnProducts)

  const freeShippingCallout = item?.freeShippingCallout
  const [addedVariants, setAddedVariants] = useState<
    { product: ShopifyProduct; variant: ShopifyProductVariant }[]
  >([])
  const price = selectedVariant?.price ? parseFloat(selectedVariant.price) : 0

  const dimensionsLinkClicked = () => {
    tracking.elementClicked(`${item.internalName}-see-dimensions`)
    viewDimensionsClicked()
  }

  const updateAddOnQuantity = (variantId: string) => {
    const currentQuantity = addedProductVariantQuantities[variantId] || 0

    updateAddOnProductVariantQuantity(variantId, currentQuantity + 1)
  }

  useEffect(() => {
    const newVariants = addOnProducts?.flatMap(productItem =>
      ((productItem.product.variants as unknown) as VariantConn).edges
        .map(edge => edge.node)
        .filter(variant => addedProductVariantQuantities[variant.id] > 0)
        .map(variant => ({ product: productItem.product, variant }))
    )

    setAddedVariants(newVariants || [])
  }, [addedProductVariantQuantities, addOnProducts])

  return (
    <Container>
      <ProductPageVariantOptions
        selectableOptions={selectableOptions}
        selectedVariant={selectedVariant}
        updateSelectedVariant={updateSelectedVariant}
      />
      {addOnProducts?.length > 0 && (
        <AddOnAccordion
          styleType="subtle"
          eventTrackingId={`PDP Add Ons for ${item?.title}`}
          title={item.addOnProductsTitleText || "Pairs With"}
          openByDefault
        >
          {addOnProducts.map(addOnProduct => (
            <AddOnProductContainer
              key={addOnProduct?.product?.id}
              item={addOnProduct}
              mode="dark"
              onAddToCartClick={updateAddOnQuantity}
            />
          ))}
        </AddOnAccordion>
      )}
      {addedVariants?.length > 0 && (
        <AddedVariantsSummary>
          <AddOnLineItemsContainer>
            <AddedProductsTitle>Add-ons</AddedProductsTitle>
            {addedVariants.map(({ product, variant }) => (
              <AddOnLineItem key={variant.id}>
                <AddOnLineItemTitle>
                  {product.title} - {variant.title}
                </AddOnLineItemTitle>
                <Incrementor
                  quantity={addedProductVariantQuantities[variant.id]}
                  minAllowedQuantity={0}
                  variant="compact"
                  setQuantity={quantity => {
                    updateAddOnProductVariantQuantity(variant.id, quantity)
                  }}
                />
              </AddOnLineItem>
            ))}
          </AddOnLineItemsContainer>
          <TotalLine>
            <AddOnsTotal>Total</AddOnsTotal>
            <div>+{usdFmt(addedProductVariantsPrice)}</div>
          </TotalLine>
        </AddedVariantsSummary>
      )}
      <DimensionsShippingContainer
        $dimensionsButtonEnabled={dimensionsButtonEnabled}
      >
        {dimensionsButtonEnabled && (
          <DimensionsContainer>
            <RulerSvg role="img" aria-label="Ruler" />
            <DimensionsButton type="button" onClick={dimensionsLinkClicked}>
              See Dimensions
            </DimensionsButton>
          </DimensionsContainer>
        )}
        <FreeShippingCallout>{freeShippingCallout}</FreeShippingCallout>
      </DimensionsShippingContainer>
      <ActionContainer $hasPrice={price > 0} ref={intersectionRef}>
        {price > 0 && (
          <Incrementor quantity={quantity} setQuantity={setQuantity} />
        )}
        <AddToCartButton
          loading={loading}
          actionRequired={actionRequired}
          text={actionRequiredText}
          fullWidth
        />
      </ActionContainer>
      <AffirmTeaserSanity price={price} />
      {selectedVariant?.variantMetadata?.value && (
        <CalloutContainer>
          <Callouts />
        </CalloutContainer>
      )}
      {waitlistPopupVisible && selectedVariant && (
        <Waitlist onClose={() => setWaitlistPopupVisible(false)}>
          <WaitlistSignup
            sku={selectedVariant?.sku || ""}
            variantId={decodeVariantId(
              selectedVariant?.shopifyVariant?.variantId || ""
            )}
          />
        </Waitlist>
      )}
    </Container>
  )
}
