import { useEffect, useContext, useState, useMemo } from "react"
import { Query } from "typings/graphql"
import { ProductContext } from "contexts/ProductContext"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { useQuery } from "@apollo/client"
import { shopifyApolloClient } from "config/apollo-clients/shopify"
import { getProductById } from "graphql/queries/product"
import {
  AvailableOptionPairs,
  ProductMetaVariantConn,
  ProductMetaVariantConnNode,
} from "typings/modules"
import {
  areVariantTypesEqual,
  getAvailableOptions,
  getDefaultVariant,
  getBase64Id,
  getVariantOptionPairs,
  mergeSanityShopifyVariants,
  selectAssets,
} from "utils/productUtils"

import { useLocation } from "@reach/router"
import { tracking } from "utils/tracking"
import { decodeVariantId, encodeVariantId } from "utils/decodeId"
import { Config } from "config/brands"
import { siteConstants } from "constants/site"
import { navigate } from "gatsby"
import { applyTagBasedDiscounts } from "utils/discountUtils"

type Props = {
  staticData: Query
}

export const ProductStateManager = ({ staticData }: Props): null => {
  const item = staticData.allSanityProduct.nodes[0]
  const product = staticData.allShopifyProduct.nodes[0]
  const addOnProductShopifyIds = item?.addOnProducts?.map(item =>
    getBase64Id(item.product.shopifyProduct.shopifyId)
  )
  const sanityVariants = item?.variants
  const variantTypes = item?.variantTypes
  const typesWithAssets = item.typesWithAssets
  const productPathPrefix = useProductPathPrefix()

  const {
    setItem,
    setProduct,
    variants,
    setVariants,
    addOnProductVariants,
    setAddOnProductVariants,
    addedProductVariantQuantities,
    setAddedProductVariantsPrice,
    selectedVariant,
    setSelectedVariant,
    setSelectedAssets,
    setAvailableOptions,
    setVariantTypes,
    resetProduct,
    dataPopulationComplete,
    setDataPopulationComplete,
    availableOptions,
    setSelectableOptions,
  } = useContext(ProductContext)

  const [sanityShopifyDataMatch, setSanityShopifyDataMatch] = useState(false)
  const location = useLocation()
  const [currLocation, setCurrLocation] = useState("")
  const [
    productShopifyNode,
    setProductShopifyNode,
  ] = useState<ProductMetaVariantConnNode>(null)
  const [addOnProductShopifyNodes, setAddOnproductShopifyNodes] = useState<
    ProductMetaVariantConnNode[]
  >(null)

  const {
    data: shopifyData,
    loading: shopifyLoading,
  } = useQuery<ProductMetaVariantConn>(getProductById, {
    variables: {
      ids: [product?.shopifyId, ...addOnProductShopifyIds],
    },
    client: shopifyApolloClient,
  })

  const dynamicData = useMemo(() => applyTagBasedDiscounts(shopifyData), [
    shopifyData,
  ])

  useEffect(() => {
    if (shopifyLoading) {
      return
    }

    const productNode = dynamicData?.nodes.find(
      ({ id }) => getBase64Id(id) === product.shopifyId
    )

    const addOnNodes = dynamicData?.nodes.filter(
      ({ id }) => getBase64Id(id) !== product.shopifyId
    )

    setProductShopifyNode(productNode)
    setAddOnproductShopifyNodes(addOnNodes)
  }, [dynamicData?.nodes, shopifyLoading, product])

  // initializeWithTemplateData
  useEffect(() => {
    // check if sanity product is connected with the right shopify one
    const itemHandle = item?.shopifyProduct.handle ?? ""
    const productHandle = product?.handle ?? ""
    if (itemHandle !== "" && itemHandle === productHandle) {
      resetProduct()
      setItem(item)
      setProduct(product)
    }
    return () => {
      resetProduct()
    }
  }, [])

  // shopifySanityDataMatchCheck
  useEffect(() => {
    const itemHandle = item?.shopifyProduct.handle ?? ""
    const nodeHandle = productShopifyNode?.handle ?? ""
    if (itemHandle !== "" && itemHandle === nodeHandle) {
      setSanityShopifyDataMatch(true)
    }
  }, [item?.shopifyProduct.handle, productShopifyNode])

  // populateWithShopifyData
  useEffect(() => {
    if (
      !item ||
      !productShopifyNode ||
      !sanityShopifyDataMatch ||
      dataPopulationComplete
    ) {
      return
    }
    const shopifyVariants = productShopifyNode.variants?.edges.map(e => e.node)
    const mergedVariants = mergeSanityShopifyVariants(
      sanityVariants,
      shopifyVariants
    )

    const availableOptions = getAvailableOptions(mergedVariants, variantTypes)
    setVariants(mergedVariants)
    setAvailableOptions(availableOptions)
    setVariantTypes(variantTypes)
    setDataPopulationComplete(true)
  }, [
    item,
    sanityShopifyDataMatch,
    productShopifyNode,
    dataPopulationComplete,
    setVariants,
  ])

  useEffect(() => {
    if (item?.addOnProducts.length <= 0 || !addOnProductShopifyNodes) {
      return
    }
    const shopifyVariants = addOnProductShopifyNodes
      .flatMap(node => node?.variants?.edges)
      .map(e => e.node)

    const addOnVariants = item.addOnProducts.flatMap(
      item => item.product.variants
    )
    const mergedVariants = mergeSanityShopifyVariants(
      addOnVariants,
      shopifyVariants
    )
    setAddOnProductVariants(mergedVariants)
  }, [item?.addOnProducts, addOnProductShopifyNodes])

  // setSelectedVariant
  useEffect(() => {
    if (!dataPopulationComplete) {
      return
    }
    const url = new URL(window.location.href)
    const queryVariantId = url.searchParams.get("variant")

    if (queryVariantId) {
      const encodedVariant = encodeVariantId(queryVariantId)
      const selectedVariant =
        variants.find(variant => {
          return variant?.shopifyVariant?.variantId === encodedVariant
        }) || getDefaultVariant(variants)
      setSelectedVariant(selectedVariant)
      setSelectedAssets(
        selectAssets(variants, selectedVariant, typesWithAssets)
      )
    } else {
      const defaultVariant = getDefaultVariant(variants)

      setSelectedVariant(defaultVariant)
      setSelectedAssets(defaultVariant.assets)
    }
  }, [dataPopulationComplete, typesWithAssets, variants])

  useEffect(() => {
    if (addOnProductVariants.length > 0) {
      const totalPrice = addOnProductVariants.reduce((total, variant) => {
        if (addedProductVariantQuantities[variant.id] > 0) {
          const price = variant.price ? parseFloat(variant.price) : 0
          return total + price * addedProductVariantQuantities[variant.id]
        }
        return total
      }, 0)

      setAddedProductVariantsPrice(totalPrice)
    }
  }, [addOnProductVariants, addedProductVariantQuantities])

  useEffect(() => {
    if (!availableOptions || !selectedVariant || !variants) {
      return
    }

    let primaryVariantType =
      item?.primaryVariantType || Config.defaultPrimaryVariantType

    primaryVariantType =
      variantTypes.find(vt => areVariantTypesEqual(vt, primaryVariantType)) ||
      variantTypes.find(vt =>
        areVariantTypesEqual(vt, siteConstants.fallbackPrimaryVariant)
      ) ||
      variantTypes[0]

    const optionPairs = Object.keys(availableOptions).reduce((acc, type) => {
      acc[type] = getVariantOptionPairs(
        variants,
        selectedVariant,
        type,
        availableOptions[type],
        primaryVariantType === type
      )

      return acc
    }, {} as AvailableOptionPairs)

    setSelectableOptions(optionPairs)
  }, [selectedVariant, variants, availableOptions, item?.primaryVariantType])

  // updateQueryParamsOnVariantChange
  useEffect(() => {
    if (!selectedVariant || !variants.length) {
      return
    }
    const id = decodeVariantId(selectedVariant?.shopifyVariant?.variantId || "")

    const url = new URL(window.location.href)
    url.searchParams.set("variant", id)

    navigate(`${window.location.pathname}?${url.searchParams.toString()}`, {
      replace: true,
    })
  }, [selectedVariant, variants])

  // updateLocation
  useEffect(() => {
    setCurrLocation(location.search)
  }, [location.search])

  // checkLocation
  useEffect(() => {
    if (!location.search && currLocation !== location.search) {
      setDataPopulationComplete(false)
      setSelectedVariant(null)
    }
  }, [currLocation, location])

  // trackProductViewed
  useEffect(() => {
    if (product && selectedVariant) {
      tracking.productViewed(product, productPathPrefix, selectedVariant)
    }
  }, [product, selectedVariant])

  return null
}
